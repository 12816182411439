<template>
  <router-view />
</template>

<style>
html {
  font-family: Arial, sans-serif;
}

.logo {
  width: 100%;
  height: auto;
}
</style>
