import { createRouter, createWebHistory } from 'vue-router'
import Accept from '../views/Accept.vue'
import Decline from '../views/Decline.vue'

const routes = [
  {
    path: '/accept',
    name: 'accept',
    component: Accept,
    props: route => ({ code: route.query.code })
  },
  {
    path: '/decline',
    name: 'decline',
    component: Decline,
    props: route => ({ code: route.query.code })
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
