<template>
  <div>
    <div class="row text-pinchuk" style="margin-top: 20%;">
      <div class="col-lg-12 text-center">
        <p style="text-align: center;justify-content: center;">
          <b style="font-size: x-large;">Thank you for the confirmation!</b>
          <br />
          We will be delighted to see you at the opening of the exhibition
          <br />
          <b>From Ukraine: Dare to Dream</b>
          <br />
          Thursday, 18th of April
          <br />
          7:00 pm
          <br />
          at the Palazzo Contarini Polignac
          <br />
          Palazzo Contarini-Polignac, 874, Dorsoduro, 30123 Venezia, Italy
          <br />
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  props: ['code'],
  mounted() {
    this.sendGETRequest();
  },
  methods: {
    sendGETRequest() {
      const url = 'https://picpic.w3b.services/site/visit';
      const params = {
        code: this.code,
        type: 1,

      };

      axios.get(url, { params })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style>
@media(max-width: 768px) {
  .text-pinchuk {
    margin-top: 60%;
  }
}
</style>
