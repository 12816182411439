<template>
  <div>
    <div class="row text-pinchuk" style="margin-top: 22%;">
      <div class="col-lg-11 text-center">
        <p style="text-align: center;justify-content: center;">
          <b style="font-size: x-large;">We are sorry that you will not be able to come to the opening of the
            exhibition</b>
          <br />
          <b> From Ukraine: Dare to Dream</b>
          <br />
          Thank you for considering our invitation.
          <br />
          If there is an opportunity for you to attend, we would be delighted to see you there.
          <br />

        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['code'],
  mounted() {
    this.sendGETRequest();
  },
  methods: {
    sendGETRequest() {
      const url = 'https://picpic.w3b.services/site/visit';
      const params = {
        code: this.code,
        type: 2
      };

      axios.get(url, { params })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>
<style>
@media(max-width: 768px) {
  .text-pinchuk {
    margin-top: 60%;
  }
}
</style>
